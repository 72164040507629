export const URI_MAIN = 'main';
export const URI_V1 = 'v1/';

export const URI_AJAX = 'ajax';
export const URI_SLIDE = URI_V1 + 'extend/slide';

export const URI_POST = URI_V1 + 'post/';
export const URI_POSTFILTER = URI_POST + 'filter';
export const URI_POSTDETAIL = URI_POST + 'detail';
export const URI_POSTCATEGORY = URI_POST + 'category';
export const URI_POSTMOSTVIEW = URI_POST + 'mostview';

export const URI_PRODUCT = URI_V1 + 'product/';
export const URI_PRODUCTFILTER = URI_PRODUCT + 'filter';
export const URI_PRODUCTDETAIL = URI_PRODUCT + 'detail';
export const URI_PRODUCTCATEGORY = URI_PRODUCT + 'category';
export const URI_PRODUCTMOSTVIEW = URI_PRODUCT + 'mostview';

export const URI_PAGE = URI_V1 + 'page/';
export const URI_PAGEFILTER = URI_PAGE + 'filter';
export const URI_PAGEDETAIL = URI_PAGE + 'detail';
export const URI_PAGETYPE = URI_PAGE + 'type';

export const URI_SIDEBAR = URI_V1 + 'sidebar/';
export const URI_SIDEBARFILTER = URI_SIDEBAR + 'filter';
export const URI_SIDEBARDETAIL = URI_SIDEBAR + 'detail';
export const URI_SIDEBARTYPE = URI_SIDEBAR + 'type';
