import {combineReducers}from "redux";
import {routerReducer} from 'react-router-redux';
import {reducer as awaitReducer} from 'redux-await';

import extras from './extras';
import extend from './extend';
import post from './post';
import product from './product';
import page from './page';
import sidebar from './sidebar';

const rootReducer = combineReducers({
    await: awaitReducer, routing: routerReducer,
    extras, extend, post, product, page, sidebar
})

export default rootReducer;