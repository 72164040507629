import React, {Component, Suspense, lazy} from 'react';
import {withRouter, Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'redux-await';
import {bindActionCreators} from 'redux';

import {decrypto} from  '../helpers';
import * as actExtras from '../redux/actions/extras';

import CircularProgress from '../utils/CircularProgress';
import App from './routes';

const Template = lazy(() => import('../components/Template'));

class Main extends Component {
    constructor(props) {
        super();

        this.state = {

        };
    }

    UNSAFE_componentWillMount() {
        let extras = decrypto(this.props.extras, false, true);
        this.props.actExtras.SaveExtras(JSON.parse(extras))
    }

    render() {
        // const {location, authUser} = this.props;

        // if (location.pathname === '/') {
        //     if (!authUser) {
        //         return ( <Redirect to='/login' /> )
        //     } else {
        //         return ( <Redirect to='/dashboard' /> )
        //     }
        // }

        return (
            <Suspense fallback={<CircularProgress/>}>
                <Switch>
                    <Template><App/></Template>
                </Switch>
            </Suspense>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        authUser: false
    }
}

const mapDispatchtoProps = (dispatch) => {
    return {
        actExtras: bindActionCreators(actExtras, dispatch)
    }
}

export default withRouter(connect(mapStatetoProps, mapDispatchtoProps)(Main));