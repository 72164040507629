import React from "react";
import ReactDOM from "react-dom";
import {Provider} from 'react-redux';

import 'antd/dist/antd.css';
import './assets/css/index.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import store, {history} from './store';
import App from './contains/app';
import * as serviceWorker from './serviceWorker';

history.listen(function(loc) {
	// Don't scroll to top if user presses back
	// - if (loc.action === 'POP' || loc.action === 'REPLACE') is an option
	if (loc.action === 'POP') {
		return;
	}

	// Allow the client to control scroll-to-top using location.state
	if (loc.state && loc.state.scroll !== undefined && !loc.state.scroll) {
		return;
	}

	// 200ms delay hack (for Firefox?)
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 200);
});

ReactDOM.render(
	<Provider store={store}>
		<App history={history} data={JSON.parse(window.__initialData__)} />
	</Provider>,
	document.getElementById('app')
);

// enables hot module replacement if plugin is installed
if (module.hot) module.hot.accept();

serviceWorker.unregister();