import React, {Component, Suspense, lazy} from 'react';
import {Route, Switch} from 'react-router-dom';

import CircularProgress from '../utils/CircularProgress';

const Home = lazy(() => import('../components/home'));
const Page = lazy(() => import('../components/page'));
const Post = lazy(() => import('../components/post'));
const Product = lazy(() => import('../components/product'));

const routes = [
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/page/:slug?",
        component: Page
    },
    {
        path: "/post/:slug?",
        component: Post
    },
    {
        path: "/post/:slug?",
        component: Post
    },
    {
        path: "/product/:slug?",
        component: Product
    }
]

const RouteWithSubRoutes = (route) => {
    return (
        <Route
			path={route.path}
			render={props => (
				// pass the sub-routes down to keep nesting
				<route.component {...props} routes={route.routes} />
			)}
		/>
    );
};

class App extends Component {
    render() {
        return (
            <Suspense fallback={<CircularProgress/>}>
                <Switch>
                    {
                        routes.map((route, i) => {
                            return (
                                <RouteWithSubRoutes key={i} {...route} />
                                // <Route key={i} {...route} />
                            )
                        })
                    }
                </Switch>
            </Suspense>
        )
    }
};

export default App;