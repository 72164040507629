export default {
    cancelToken: [],
    setAjax: [],
    extraslist: [],
    slidelist: [],
    postlist: [],
    postcategorylist: [],
    postmostviewlist: [],
    postdetail: [],
    productlist: [],
    productcategorylist: [],
    productmostviewlist: [],
    productdetail: [],
    pagelist: [],
    pagetypelist: [],
    pagedetail: [],
    sidebarlist: [],
    sidebartypelist: [],
    sidebardetail: [],
    //session: !!sessionStorage.auth
}