import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    pagelist: initialState.pagelist,
    pagetypelist: initialState.pagetypelist,
    pagedetail: initialState.pagedetail
};

const pageReducer = (state = data, action, multiple) => {
    switch (action.type) {
        case types.CLEAR_PAGE:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PAGE])
                state.cancelToken[types.CLEAR_PAGE].map((v, i) => v.cancel('Page got unmounted '+i));

            state = {
                ...state,
                loading: true,
                pagelist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PAGE:
            state = {
                ...state,
                loading: true,
                pagelist: propLess(action, state, 'pagelist')
            }            
            break;  
        case types.CLEAR_PAGE_TYPE:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PAGE_TYPE])
                state.cancelToken[types.CLEAR_PAGE_TYPE].map((v, i) => v.cancel('Page type got unmounted '+i));

            state = {
                ...state,
                loading: true,
                pagetypelist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PAGE_TYPE:
            state = {
                ...state,
                loading: true,
                pagetypelist: propLess(action, state, 'pagetypelist')
            }            
            break; 
        case types.CLEAR_PAGE_DETAIL:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PAGE_DETAIL])
                state.cancelToken[types.CLEAR_PAGE_DETAIL].map((v, i) => v.cancel('Page detail got unmounted '+i));

            state = {
                ...state,
                loading: true,
                pagedetail: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PAGE_DETAIL:
            state = {
                ...state,
                loading: true,
                pagedetail: propLess(action, state, 'pagedetail')
            }            
            break;    

        default:
    }

    return state;
}

export default pageReducer;
