import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    slidelist: initialState.slidelist
};

const extendReducer = (state = data, action) => {
    switch (action.type) {
        case types.CLEAR_SLIDE:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_SLIDE])
                state.cancelToken[types.CLEAR_SLIDE].map((v, i) => v.cancel('Slide got unmounted '+i));

            state = {
                ...state,
                loading: true,
                slidelist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_SLIDE:            
            state = {
                ...state,
                loading: true,
                slidelist: propLess(action, state, 'slidelist')
            }            
            break;    

        default:
    }

    return state;
}

export default extendReducer;
