import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    sidebarlist: initialState.sidebarlist,
    sidebartypelist: initialState.sidebartypelist,
    sidebardetail: initialState.sidebardetail
};

const sidebarReducer = (state = data, action) => {
    switch (action.type) {
        case types.CLEAR_SIDEBAR:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_SIDEBAR])
                state.cancelToken[types.CLEAR_SIDEBAR].map((v, i) => v.cancel('Sidebar got unmounted '+i));

            state = {
                ...state,
                loading: true,
                sidebarlist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_SIDEBAR:
            state = {
                ...state,
                loading: true,
                sidebarlist: propLess(action, state, 'sidebarlist')
            }            
            break;
        case types.CLEAR_SIDEBAR_TYPE:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_SIDEBAR_TYPE])
                state.cancelToken[types.CLEAR_SIDEBAR_TYPE].map((v, i) => v.cancel('Sidebar type got unmounted '+i));

            state = {
                ...state,
                loading: true,
                sidebartypelist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_SIDEBAR_TYPE:
            state = {
                ...state,
                loading: true,
                sidebartypelist: propLess(action, state, 'sidebartypelist')
            }            
            break; 
        case types.CLEAR_SIDEBAR_DETAIL:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_SIDEBAR_DETAIL])
                state.cancelToken[types.CLEAR_SIDEBAR_DETAIL].map((v, i) => v.cancel('Sidebar detail got unmounted '+i));

            state = {
                ...state,
                loading: true,
                sidebardetail: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_SIDEBAR_DETAIL:
            state = {
                ...state,
                loading: true,
                sidebardetail: propLess(action, state, 'sidebardetail')
            }            
            break;    

        default:
    }

    return state;
}

export default sidebarReducer;
