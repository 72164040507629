import {AWAIT_MARKER} from 'redux-await';
import axios from 'axios';

import * as types from '../../constants/InitialType';
import * as ihttp from '../../constants/initialHttp';
import HttpApi from '../../api';

export const SaveExtras = (dt) => async (dispatch) => {
    dispatch({type: types.LOAD_EXTRAS, AWAIT_MARKER, payload: dt});
}

export const clearAjax = () => async (dispatch) => {
    dispatch({type: types.CLEAR_AJAX, AWAIT_MARKER, payload: false, multiple: {type: types.CLEAR_AJAX}});
}

export function setAjaxHttp(dt, state, dispatch, clear) {
    let cancelToken = axios.CancelToken.source();
    dispatch({type: clear, AWAIT_MARKER, payload: cancelToken, multiple: {type: clear, nested: true}});

    return HttpApi.callPost(ihttp.URI_AJAX, dt, 'site', false, cancelToken).then(dt => {
        return dt;
    }).catch(err => {
        throw(err);
    });
}

export const setAjax = (dt, wait, timer = 1000, state) => async (dispatch) => {
    let data = await new Promise(resolve => {
        setTimeout(((dt, state, dispatch, types) => {
            resolve(setAjaxHttp(dt, state, dispatch, types.CLEAR_AJAX)) 
        })(dt, state, dispatch, types), timer)
    })

    let multiple = (state && state.type) ? Object.assign(state, {type: state.type}) : state;
    multiple = (state && state.nested) ? Object.assign(state, {nested: state.nested}) : multiple;

    if (state && state.merge === false) delete multiple.type;
    dispatch({type: types.SET_AJAX, AWAIT_MARKER, payload: data, multiple: multiple});

    return data;
}