export function propLess (action, state, data) {
    let dt = action.payload;
    if (dt.unmount === undefined) {
        if (Object.keys(state[data]).length > 0) {
            dt = state[data];
            
            if (action.multiple && action.multiple.type) {
                if (action.multiple.nested) {
                    if (typeof action.multiple.nested === 'boolean') {
                        if (dt[action.multiple.type] && dt[action.multiple.type] !== undefined) {
                            dt[action.multiple.type].push(action.payload)
                        } else {
                            dt[action.multiple.type] = [action.payload]
                        }                  
                    } else {
                        if (dt[action.multiple.type] && dt[action.multiple.type] !== undefined) {
                            if (Object.prototype.toString.call(dt[action.multiple.type]) === '[object Array]')
                            dt[action.multiple.type].push({
                                key: action.multiple.nested,
                                data: action.payload
                            })
                        } else {
                            dt[action.multiple.type] = [{
                                key: action.multiple.nested,
                                data: action.payload
                            }]
                        }
                    }
                } else {
                    dt[action.multiple.type] = action.payload;
                }
            }
        } else {
            if (action.multiple && action.multiple.type) {
                if (action.multiple.nested) {
                    if (typeof action.multiple.nested === 'boolean') {
                        dt = {[action.multiple.type]: [action.payload]}
                    } else {
                        dt = {[action.multiple.type]: [
                            {
                                key: action.multiple.nested,
                                data: action.payload
                            }
                        ]}
                    }                    
                } else {
                    dt = {[action.multiple.type]: action.payload}
                }                    
            }
        }
    } else {
        dt = state[data];

        if (process.env.NODE_ENV === 'development')
        console.log('CANCELLED', data)
    }

    return dt;
}