import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    productlist: initialState.productlist,
    productcategorylist: initialState.productcategorylist,
    productmostviewlist: initialState.productmostviewlist,
    productdetail: initialState.productdetail
};

const productReducer = (state = data, action, multiple) => {
    switch (action.type) {
        case types.CLEAR_PRODUCT:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PRODUCT])
                state.cancelToken[types.CLEAR_PRODUCT].map((v, i) => v.cancel('Product got unmounted '+i));

            state = {
                ...state,
                loading: true,
                productlist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PRODUCT:
            state = {
                ...state,
                loading: true,
                productlist: propLess(action, state, 'productlist')
            }            
            break;  
        case types.CLEAR_PRODUCT_CATEGORY:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PRODUCT_CATEGORY])
                state.cancelToken[types.CLEAR_PRODUCT_CATEGORY].map((v, i) => v.cancel('Product category got unmounted '+i));

            state = {
                ...state,
                loading: true,
                productcategorylist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PRODUCT_CATEGORY:
            state = {
                ...state,
                loading: true,
                productcategorylist: propLess(action, state, 'productcategorylist')
            }            
            break;
        case types.CLEAR_PRODUCT_MOSTVIEW:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PRODUCT_MOSTVIEW])
                state.cancelToken[types.CLEAR_PRODUCT_MOSTVIEW].map((v, i) => v.cancel('Product mostview got unmounted '+i));

            state = {
                ...state,
                loading: true,
                productmostviewlist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PRODUCT_MOSTVIEW:
            state = {
                ...state,
                loading: true,
                productmostviewlist: propLess(action, state, 'productmostviewlist')
            }            
            break; 
        case types.CLEAR_PRODUCT_DETAIL:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_PRODUCT_DETAIL])
                state.cancelToken[types.CLEAR_PRODUCT_DETAIL].map((v, i) => v.cancel('Product detail got unmounted '+i));

            state = {
                ...state,
                loading: true,
                productdetail: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_PRODUCT_DETAIL:
            state = {
                ...state,
                loading: true,
                productdetail: propLess(action, state, 'productdetail')
            }            
            break;    

        default:
    }

    return state;
}

export default productReducer;
