import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    extraslist: initialState.extraslist,
    setAjax: initialState.setAjax
};

const extrasReducer = (state = data, action) => {
    switch (action.type) {
        case types.LOAD_EXTRAS:
            state = {
                ...state,
                loading: true,
                extraslist: propLess(action, state, 'extraslist')
            }
            break;
        case types.CLEAR_AJAX:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_AJAX])
                state.cancelToken[types.CLEAR_AJAX].map((v, i) => v.cancel('Ajax got unmounted '+i));

            state = {
                ...state,
                loading: true,
                setAjax: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.SET_AJAX:            
            state = {
                ...state,
                loading: true,
                setAjax: propLess(action, state, 'setAjax')
            }            
            break;

        default:
    }

    return state;
}

export default extrasReducer;
