export const DF_LANG = 'id';

export const INIT_URL = 'INIT_URL';
export const LOAD_EXTRAS = 'LOAD_EXTRAS';

export const CLEAR_AJAX = 'CLEAR_AJAX';
export const SET_AJAX = 'SET_AJAX';

export const CLEAR_SLIDE = 'CLEAR_SLIDE';
export const LOAD_SLIDE = 'LOAD_SLIDE';

export const CLEAR_POST = 'CLEAR_POST';
export const LOAD_POST = 'LOAD_POST';

export const CLEAR_POST_CATEGORY = 'CLEAR_POST_CATEGORY';
export const LOAD_POST_CATEGORY = 'LOAD_POST_CATEGORY';

export const CLEAR_POST_MOSTVIEW = 'CLEAR_POST_MOSTVIEW';
export const LOAD_POST_MOSTVIEW = 'LOAD_POST_MOSTVIEW';

export const CLEAR_POST_DETAIL = 'CLEAR_POST_DETAIL';
export const LOAD_POST_DETAIL = 'LOAD_POST_DETAIL';

export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';

export const CLEAR_PRODUCT_CATEGORY = 'CLEAR_PRODUCT_CATEGORY';
export const LOAD_PRODUCT_CATEGORY = 'LOAD_PRODUCT_CATEGORY';

export const CLEAR_PRODUCT_MOSTVIEW = 'CLEAR_PRODUCT_MOSTVIEW';
export const LOAD_PRODUCT_MOSTVIEW = 'LOAD_PRODUCT_MOSTVIEW';

export const CLEAR_PRODUCT_DETAIL = 'CLEAR_PRODUCT_DETAIL';
export const LOAD_PRODUCT_DETAIL = 'LOAD_PRODUCT_DETAIL';

export const CLEAR_PAGE = 'CLEAR_PAGE';
export const LOAD_PAGE = 'LOAD_PAGE';

export const CLEAR_PAGE_TYPE = 'CLEAR_PAGE_TYPE';
export const LOAD_PAGE_TYPE = 'LOAD_PAGE_TYPE';

export const CLEAR_PAGE_DETAIL = 'CLEAR_PAGE_DETAIL';
export const LOAD_PAGE_DETAIL = 'LOAD_PAGE_DETAIL';

export const CLEAR_SIDEBAR = 'CLEAR_SIDEBAR';
export const LOAD_SIDEBAR = 'LOAD_SIDEBAR';

export const CLEAR_SIDEBAR_TYPE = 'CLEAR_SIDEBAR_TYPE';
export const LOAD_SIDEBAR_TYPE = 'LOAD_SIDEBAR_TYPE';

export const CLEAR_SIDEBAR_DETAIL = 'CLEAR_SIDEBAR_DETAIL';
export const LOAD_SIDEBAR_DETAIL = 'LOAD_SIDEBAR_DETAIL';