import { propLess } from '../propless';

import * as types from '../../constants/InitialType';
import initialState from '../../constants/initialState';

let data = {
    loading: false,
    cancelToken: initialState.cancelToken,
    postlist: initialState.postlist,
    postcategorylist: initialState.postcategorylist,
    postmostviewlist: initialState.postmostviewlist,
    postdetail: initialState.postdetail
};

const postReducer = (state = data, action, multiple) => {
    switch (action.type) {
        case types.CLEAR_POST:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_POST])
                state.cancelToken[types.CLEAR_POST].map((v, i) => v.cancel('Post got unmounted '+i));

            state = {
                ...state,
                loading: true,
                postlist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_POST:
            state = {
                ...state,
                loading: true,
                postlist: propLess(action, state, 'postlist')
            }            
            break;  
        case types.CLEAR_POST_CATEGORY:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_POST_CATEGORY])
                state.cancelToken[types.CLEAR_POST_CATEGORY].map((v, i) => v.cancel('Post category got unmounted '+i));

            state = {
                ...state,
                loading: true,
                postcategorylist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_POST_CATEGORY:
            state = {
                ...state,
                loading: true,
                postcategorylist: propLess(action, state, 'postcategorylist')
            }            
            break;
        case types.CLEAR_POST_MOSTVIEW:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_POST_MOSTVIEW])
                state.cancelToken[types.CLEAR_POST_MOSTVIEW].map((v, i) => v.cancel('Post mostview got unmounted '+i));

            state = {
                ...state,
                loading: true,
                postmostviewlist: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_POST_MOSTVIEW:
            state = {
                ...state,
                loading: true,
                postmostviewlist: propLess(action, state, 'postmostviewlist')
            }            
            break; 
        case types.CLEAR_POST_DETAIL:
            if (!action.payload && state.cancelToken && state.cancelToken[types.CLEAR_POST_DETAIL])
                state.cancelToken[types.CLEAR_POST_DETAIL].map((v, i) => v.cancel('Post detail got unmounted '+i));

            state = {
                ...state,
                loading: true,
                postdetail: [],
                cancelToken: propLess(action, state, 'cancelToken')
            }
            break;
        case types.LOAD_POST_DETAIL:
            state = {
                ...state,
                loading: true,
                postdetail: propLess(action, state, 'postdetail')
            }            
            break;    

        default:
    }

    return state;
}

export default postReducer;
